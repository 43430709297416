import { FormikErrors } from "formik";
import { DocumentsInformation, FinancialInfoContainer, PersonalInfoContainer, RequestInfoContainer} from "../..";
import { ReferenceDocument } from "../ReferenceDocument/ReferenceDocument";
import { PayInfo } from "../../Steps/InfoPay/InfoPay";

interface RenderContainerProps {
    errors?: FormikErrors<{}>
    values?: any;
    step: 'credit_information' | 'person_information' | 'finance_information' | 'reference_information' | 'pay_information';
    setPopup: React.Dispatch<React.SetStateAction<string>>;
}

export function RenderContainer(props: RenderContainerProps) {
    const {step, errors, values,setPopup} = props;
    const renderOption = () => {
        switch (step) {
            case 'credit_information':
                return <RequestInfoContainer errors={errors} values={values}/>
            case 'person_information':
                 return <PersonalInfoContainer errors={errors} values={values} setPopup={setPopup}/>
            case 'finance_information':
                 return <FinancialInfoContainer errors={errors} values={values}/>
            case 'reference_information':
                return <ReferenceDocument  errors={errors} values={values}/>
            case 'pay_information':
                return <PayInfo  errors={errors} values={values}/>
        }
    };
    return (
        <div>{renderOption()}</div>
    )
}