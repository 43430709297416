import { Wizard} from "../../components";
import './Home.scss';
import * as Yup from "yup";
import {parse} from "date-fns";
import {useIntl} from "react-intl";
import ResponsiveAppBar from "../../components/shared/Menubar/ResponsiveAppBar";
import { useState } from "react";
import Direccion from "../../components/shared/modal/Direccion";

interface HomeProps {
}

export function Home(_props: HomeProps) {
    const intl = useIntl();
    
    const [popup, setPopup] = useState("close");
    const steps = [
        {
            key: 'credit_information',
            label: intl.formatMessage({id: "button_step_credit_information"}),
            initialValues: {
                Pws_Nombres: '',
                Pws_Apellidos1:'',
                Pws_Apellidos2:'',
                Pws_Tip_Identificacion: '',
                Pws_Identificacion: '',
                Pws_Telefono1: '',
                Pws_Correo: '',
                Pws_Telefono2: '',
                mail_confirmation: '',
                pws_cod_credi: '',
                Pws_Val_pla: '',
                Pws_Val_cuoini: '',
                Pws_Val_vehiculo: '',
                Pws_Val_finan: '',
                Pws_Val_cuo: '',
                pws_nro_cuotas: '',
                Num_poliza:'',
            },
            validationSchema: Yup.object().shape({
                Pws_Nombres: Yup.string().required('Por favor ingrese su nombre'),
                Pws_Apellidos1: Yup.string().required('Por favor ingrese su Primer Apellido'),
                /* Pws_Apellidos2: Yup.string().required('Por favor ingrese su Segundo Apellido'), */
                Pws_Tip_Identificacion: Yup.string()
                    .required('Por favor seleccione el tipo de identificación'),
                Pws_Identificacion: Yup.string().required('Por favor ingrese el número de identificación'),
                Pws_Telefono1: Yup.string().required('Por favor ingrese su número telefonico'),
                Pws_Telefono2: Yup.string(),
                Pws_Correo: Yup.string()
                    .email('Email inválido').required('Por favor ingrese su correo'),
                mail_confirmation: Yup.string()
                    .email('Email inválido').required('Por favor ingrese su correo'),
                pws_cod_credi: Yup.string()
                    .required('Por favor seleccione un opción de crédito'),
                Pws_Val_cuoini: Yup.string()
                    .required('Por favor ingrese el valor de la cuota inicial'),
                Pws_Val_pla: Yup.string()
                    .required('Por favor indique el plazo'),
                pws_nro_cuotas: Yup.string()
                    .required('Por favor indique el plazo'),
                Num_poliza: Yup.string()
                    .required('Por favor ingresar el número de poliza')
            })
        },
        {
            key: 'person_information',
            label: intl.formatMessage({id: "button_step_person_information"}),
            initialValues: {
                "Pws_Tip_person": "",
                "Pws_Tip_ocupa": "",
                "Pws_Estado_Civil": "",
                "Pws_Direccion_res": "",
                "Pws_Departamento": "",
                "Pws_Ciudad_res": "",
                "Pws_Genero": "",
                "Pws_Estrato_per": "",
                "Pws_Per_cargo": "",
                "Pws_Tip_vivienda": "",
                "Pws_Niv_estudio": "",
                "Pws_Nom_empre": "",
                "Pws_fec_ingemp": "",
                "Pws_Tip_contra": "",
                "Pws_Ant_labo": "",
                "Pws_Car_emp": "",
                "Pws_Nom_Jefedi": "",
                "Pws_Direc_emp": "",
                "Pws_Ciud_emp": "",
                "Pws_tel_emp1": "",
                "Pws_tel_emp2": "",
                "Pws_Con_Tip_identif": "",
                "Pws_Con_nomsol": "",
                "Pws_Con_identif": "",
                "Pws_Con_tel": "",
                "Pws_Fec_nacime": "",
                "Pws_Fideliza": "",
            },
            validationSchema: Yup.object().shape({
                Pws_Tip_person: Yup.string().required('(*)'),
                Pws_Tip_ocupa: Yup.string().required('(*)'),
                Pws_Estado_Civil: Yup.string().required('(*)'),
                Pws_Direccion_res: Yup.string().required('(*)'),
                Pws_Departamento: Yup.string().required('(*)'),
                Pws_Ciudad_res: Yup.string().required('(*)'),
                Pws_Genero: Yup.string().required('(*)'),
                Pws_Estrato_per: Yup.string().required('(*)'),
                Pws_Per_cargo: Yup.string().required('(*)'),
                Pws_Tip_vivienda: Yup.string().required('(*)'),
                Pws_Niv_estudio: Yup.string().required('(*)'),
                Pws_Nom_empre: Yup.string().required('(*)'),
                Pws_fec_ingemp: Yup.date().transform(function (value, originalValue) {
                    if (this.isType(value)) {
                        return value;
                    }
                    const result = parse(originalValue, "MM/DD/YYYY", new Date());
                    return result;
                })
                    .required('(*)')
                    .min("01/01/1920", "Fecha invalida"),
                Pws_Tip_contra: Yup.string().required('(*)'),
                Pws_Ant_labo: Yup.string().required('(*)'),
                Pws_Car_emp: Yup.string().required('(*)'),
                Pws_Direc_emp: Yup.string().required('(*)'),
                Pws_Ciud_emp: Yup.string().required('(*)'),
                Pws_tel_emp1: Yup.string().required('(*)'),
                Pws_tel_emp2: Yup.string().required('(*)'),
                Pws_Fideliza: Yup.string(),
                Pws_Fec_nacime: Yup.date().transform(function (value, originalValue) {
                    if (this.isType(value)) {
                        return value;
                    }
                    const result = parse(originalValue, "MM/dd/YYYY", new Date());
                    return result;
                })
                    .typeError("MM/dd/YYYY")
                    .required('(*)')
                    .min("01/01/1920", "Fecha invalida"),
                Pws_Con_nomsol: Yup.string().when('Pws_Estado_Civil', {
                    is: "1",
                    then: Yup.string().required('(*)'),
                }),
                Pws_Con_tel: Yup.string().when('Pws_Estado_Civil', {
                    is: "1",
                    then: Yup.string().required('(*)'),
                }),

            })
        },
        {
            key: 'finance_information',
            label: intl.formatMessage({id: "button_step_finance_information"}),
            initialValues: {
                s_ingreso_principal: '',
                s_otros_ingresos: '',
                s_otros_egresos: '',
                s_arriendo: '',
                s_concep_otr_ingre: '',
                s_declarante_ren: '',
                s_moneda_ext: '',
                s_monext_oper: '',
                s_tip_monext: '',
                s_cuent_ext: '',
                s_cuen_extban: '',
                s_cuen_extnum: '',
                s_cuen_extpais: '',
                s_cuen_extciudad: '',
                s_pep_recpublic: '',
                s_pep_poderpublic: '',
                s_pep_reconpublic: '',
                s_pep_pubexpue: '',
                s_pep_seggraconsa: '',
                s_pep_nompepseg: '',
                s_pep_paren: '',
                s_pep_identif: '',
            },
            validationSchema: Yup.object().shape({
                s_ingreso_principal: Yup.string().required('(*)'),
                s_otros_ingresos: Yup.string().required('(*)'),
                s_otros_egresos: Yup.string().required('(*)'),
                s_arriendo: Yup.string().required('(*)'),
                s_concep_otr_ingre: Yup.string().required('(*)'),
                s_declarante_ren: Yup.string(),
                s_moneda_ext: Yup.boolean(),
                s_monext_oper: Yup.string(),
                s_tip_monext: Yup.string(),
                s_cuent_ext: Yup.string(),
                s_cuen_extban: Yup.string(),
                s_cuen_extnum: Yup.string(),
                s_cuen_extpais: Yup.string(),
                s_cuen_extciudad: Yup.string(),
                s_pep_recpublic: Yup.string(),
                s_pep_poderpublic: Yup.string(),
                s_pep_reconpublic: Yup.string(),
                s_pep_pubexpue: Yup.string(),
                s_pep_seggraconsa: Yup.string(),
                s_pep_nompepseg: Yup.string(),
                s_pep_paren: Yup.string(),
                s_pep_identif: Yup.string(),
            })
        },
        {
            key: 'reference_information',
            label: intl.formatMessage({id: "button_step_reference_information"}),
            initialValues: {
                s_ref_tipo: '',
                s_ref_parentes: '',
                s_ref_nomcomple: '',
                s_ref_telfij: '',
                s_ref_telcel: ''
            },
            validationSchema: Yup.object().shape({
                s_ref_tipo: Yup.string(),
                s_ref_parentes: Yup.string(),
                s_ref_nomcomple: Yup.string(),
                s_ref_telfij: Yup.string(),
                s_ref_telcel: Yup.string(),
            })
        },
        {
            key: 'pay_information',
            label: 'Informacion de pago',
            initialValues: {
                banco: '',
                cEmailCod:'',
                cNumCelular:'',
                cNumTelefono: '',
                codSolicitud: '',
                codTipoCuenta: '',
                codUsuarioCreador: '',
                nombreTitular: '',
                numeroCuenta: '',
                sNumDoc: '',
                sTipoIdent: '',
                sTitular: '',
            },
            validationSchema: Yup.object().shape({
                banco: Yup.string(),
                cEmailCod: Yup.string(),
                cNumCelular: Yup.string(),
                cNumTelefono: Yup.string(),
                codSolicitud: Yup.string(),
                codTipoCuenta: Yup.string(),
                codUsuarioCreador: Yup.string(),
                nombreTitular: Yup.string(),
                numeroCuenta: Yup.string(),
                sNumDoc: Yup.string(),
                sTipoIdent: Yup.string(),
                sTitular: Yup.string()
            })
        },
    ];
    return (
        <>
         {
            popup === "open"
            ?<>
                <Direccion setPopup={setPopup}/>
            </>
            :<></>
         }
              <div> 
            <ResponsiveAppBar/>
            <Wizard steps={steps} setPopup={setPopup}/>          
        </div>
        </>
   
    )
}