import {styled} from "@mui/material/styles";
import StepConnector, {stepConnectorClasses} from "@mui/material/StepConnector";
import {StepIconProps} from "@mui/material/StepIcon";
import * as React from "react";
import Check from "@mui/icons-material/Check";
import {AccountCircle, AttachMoney, UploadFileSharp} from "@mui/icons-material";
import Paper from "@mui/material/Paper";

export const renderTypeIdentificationDescription = (type: string): string => {
    switch (type) {
        case "4":
            return "TARJETA DE IDENTIDAD"
        case "1":
            return "CEDULA"
        case "5":
            return "CEDULA EXTRANJERIA"
        case "3":
            return "NIT"
        case "2":
            return "PASAPORTE"
        default:
            return ""
    }
}

export const ColorlibConnector = styled(StepConnector)(({theme}) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 16,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:
                '#00908E',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:
                '#00908E',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#00908E',
        borderRadius: 1,
    },
}));

export const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
}>(({theme, ownerState}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#00908E',
    zIndex: 1,
    color: '#fff',
    width: '35px',
    height: '35px',
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor:
            '#00908E',
        boxShadow: '#00908E',
    }),
    ...(ownerState.completed && {
        backgroundColor:
            ' #00908E',
    }),
}));

export const Item = styled(Paper)(({theme}) => ({
    padding: theme.spacing(4),
    textAlign: 'center',
}));


