import './Authorizations.scss';
import { useIntl } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import { useEffect, useState } from 'react';
import { WLOCreditAPIService } from '../../../../services/WLOCreditService';

interface AuthorizationsInfo {
    showModal: boolean;
    onHide: () => void;
    onAccept: () => void;
}

export function Authorizations(props: AuthorizationsInfo) {
    const intl = useIntl();
    const [autorizaciones, setAutorizaciones] = useState([] as any[]);
    const [checkedItems, setCheckedItems] = useState([] as boolean[]);

    useEffect(() => {
        if (props.showModal) {
            getAutorizaciones();
        }
    }, [props.showModal]);

    const getAutorizaciones = async () => {
        try {
            const autorizacionesData = await WLOCreditAPIService.getAutorizaciones();
            setAutorizaciones(autorizacionesData);
            setCheckedItems(new Array(autorizacionesData.length).fill(false));
        } catch (error) {
            console.error('Error al obtener autorizaciones:', error);
        }
    };

    const handleCheckboxChange = (index: number) => {
        const newCheckedItems = [...checkedItems];
        newCheckedItems[index] = !newCheckedItems[index];
        setCheckedItems(newCheckedItems);
    };

    const handleAcceptAll = () => {
        setCheckedItems(new Array(autorizaciones.length).fill(true));
    };

    const areAllItemsChecked = checkedItems.every(Boolean) && checkedItems.length > 0;

    const handleAccept = () => {
        if (areAllItemsChecked) {
            props.onAccept();
        }
    };

    return (
        <Modal show={props.showModal} onHide={props.onHide} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    <h3>Autorización</h3>
                    <div className='textoModal'>
                        {'Acepta la consulta en centrales, manejo de datos y políticas de Finmeridian'}
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                <div className='textoModal'>
                    {autorizaciones.map((autorizacion: any, index: number) => (
                        <div key={index} className="autorizacion-item">
                            <label>
                            {` ${autorizacion.ICodigo}: ${autorizacion.CDescripcion}`}
                            </label>
                                <ul className='si'>
                                <li className='checkAuto'>
                                    <input 
                                        className='autorizaciones'
                                        type="checkbox" 
                                        checked={checkedItems[index] || false} 
                                        onChange={() => handleCheckboxChange(index)} 
                                    />
                                </li>
                                <li>
                                    <p>Aceptar</p>
                                 </li>
                                </ul>
                        </div>
                    ))}
                    <div className="aceptar-todo">
                        <Button 
                            variant="secondary" 
                            onClick={handleAcceptAll}
                            disabled={areAllItemsChecked}
                        >
                            Aceptar todo
                        </Button>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button 
                    variant="primary" 
                    onClick={handleAccept}
                    disabled={!areAllItemsChecked}
                >
                    Aceptar
                </Button>
                <Button variant="secondary" onClick={props.onHide}>
                    Cancelar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}