import axios from "axios";

export class AdminService {
    static async getCargos(): Promise<any> {
        try {
            const { data } = await axios.get(process.env.REACT_APP_API+`/admin/cargos`);
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async getCatalogos(catalogos:{
            pws_tip_producto:string
        }): Promise<any> {
        try {
            const {data} = await axios.post(process.env.REACT_APP_API+'/inventory/categoryQuery', catalogos);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async getOcupaciones(): Promise<any> {
        try {
            const { data } = await axios.get(process.env.REACT_APP_API+`/admin/ocupaciones`);
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async getTipoContrato(): Promise<any> {
        try {
            const { data } = await axios.get(process.env.REACT_APP_API+'/admin/tipocontrato');
            return data;
        } catch (e) {
            throw e;
        }
    }

    
    static async getColors(): Promise<any> {
        try {
            const { data } = await axios.get(process.env.REACT_APP_API+'/admin/colors');
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async getUsuarios(valor:{
        Pws_Tip_Estado:string
    }): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API+'/requests/solicitudpersonas',valor);
            return data;
        } catch (e) {
            throw e;
        } 
    }
    static async addColors(colors:any): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API+'/admin/colors', colors);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async entidadTer(i_codigo:String): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API+'/admin/entidadter', i_codigo);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async getListaEstado(i_codigo:String): Promise<any>{
        try {
            const { data } = await axios.post(process.env.REACT_APP_API+'/admin/estados', i_codigo)
            return data
        } catch (e) {
            throw e;
        }
    }
    static async getlineascredito(): Promise<any> {
        try {
            const { data } = await axios.get(process.env.REACT_APP_API+'/admin/lineascredito');
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async getPeriodos(): Promise<any> {
        try {
            const { data } = await axios.get(process.env.REACT_APP_API+'/admin/periodos');
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async gettipovivienda(): Promise<any> {
        try {
            const { data } = await axios.get(process.env.REACT_APP_API+'/admin/tipovivienda');
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async gettipoidentificacion(): Promise<any> {
        try {
            const { data } = await axios.get(process.env.REACT_APP_API+'/admin/tipoidentificacion');
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async getNivelEstudio(): Promise<any> {
        try {
            const { data } = await axios.get(process.env.REACT_APP_API+'/admin/nivelestudio');
            return data;
        } catch (e) {
            throw e;
        }
    }
    
    static async getEstadosCiviles(): Promise<any> {
        try {
            const { data } = await axios.get(process.env.REACT_APP_API+'/admin/estadosciviles');
            return data;
        } catch (e) {
            throw e;
        }
    }
    
    static async getCiudadesByUbicacion(ubicacion: string): Promise<any> {
        try {
            const { data } = await axios.get(process.env.REACT_APP_API+'admin/ciudades?s_nivel=3&s_ubicacion=${ubicacion}');
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async getCiudades(): Promise<any> {
        try {
            const { data } = await axios.get(process.env.REACT_APP_API+'/admin/ciudades?s_nivel=3');
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async getAllCiudades(): Promise<any> {
        try {
            const { data } = await axios.get(process.env.REACT_APP_API+'/admin/ciudades');
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async getDepartamentos(): Promise<any> {
        try {
            const { data } = await axios.get(process.env.REACT_APP_API+'/admin/ciudades?s_nivel=2&s_ubicacion=57');
            return data;
        } catch (e) {
            throw e;
        }
    }


    
    static async getProfesiones(): Promise<any> {
        try {
            const { data } = await axios.get(process.env.REACT_APP_API+'/admin/profesiones');
            return data;
        } catch (e) {
            throw e;
        } 
    }
    static async getProfesiones2(): Promise<any> {
        try {
            const { data } = await axios.get(process.env.REACT_APP_API+'/admin/entidadFin');
            return data;
        } catch (e) {
            throw e;
        } 
    }
    static async getReferencias(): Promise<any> {
        try {
            const { data } = await axios.get(process.env.REACT_APP_API+'/admin/referencias');
            return data;
        } catch (e) {
            throw e;
        } 
    }

    static async getOficinas(): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API+'/admin/oficinas');
            return data;
        } catch (e) {
            throw e;
        } 
    }
}