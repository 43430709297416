import {Form, Field, Formik} from 'formik';
import React, {useEffect, useState} from 'react';
import {useNavigate,useLocation} from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {AppRoutes} from '../../Router';
import './login-firm-17.scss';
import * as Yup from "yup";
import {Fab} from '@mui/material';
import {typeIdentification} from '../../__mocks__/data';
import {MenuBar} from '../../components/shared/Menubar/Menubar';
import './login-firm-17.scss';
import { useIntl } from 'react-intl';
import { useSignContext } from '../../hooks/useSignContext';
import { AuthService } from '../../services/AuthService';
import toast from 'react-hot-toast';
import ClockLoader from 'react-spinners/ClockLoader';
import { WLOCreditAPIService } from '../../services/WLOCreditService';
import { useRequestContext } from '../../hooks/useRequestContext';
import {useLoginContext} from '../../hooks/useLoginContext';
import { ConnectingAirportsOutlined } from '@mui/icons-material';
import {decodeToken} from 'react-jwt';
import Moment from 'moment';

interface LoginFirmaProps {
}

export function LoginFirma17(props: LoginFirmaProps) {
    let [isLoading, setIsLoading] = useState(false);
    const [numeroSolicitud, setNumeroSolicitud] = useState(0);
    const [valoreslogins, setvalores] = useState({}as any);
    const {user, setUser} = useLoginContext();
    const intl = useIntl();
    const {request, setRequest} = useRequestContext();
    const {sign, setSign} = useSignContext();
    const navigate = useNavigate();
    const buscar = useLocation();
    const [token, setToken] = useState<string>('')
    const [showModal, setShowModal] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [autorizaciones, setAutorizaciones] = useState([]as any);
    const [checkedItems, setCheckedItems] = useState([] as boolean[]);
    const [tiposIdentidad, setTiposIdentidad] = useState([]as any);


    const handleSubmit = async (values: any) => {   
        console.log( values.Pws_Tip_Identificacion);
              
        setIsLoading(true);
        try {
            if(termsAccepted == true){
                let datos:any = {
                    ident: values.Pws_Identificacion,
                    tipoIden: values.Pws_Tip_Identificacion
                }
                sessionStorage.setItem('identificacion', values.Pws_Identificacion);
                sessionStorage.setItem('tipoidentificacion', values.Pws_Tip_Identificacion);
                navigate(AppRoutes.LOGINOTP, {replace: true});
            }else{
                setIsLoading(false);
                toast.error('Debe aceptar las autorizaciones para continuar');
            }
        } catch (error) {
            setIsLoading(false);
            toast.error('En este momento no podemos procesar su solicitud');
        }
    };
    const handleTermsClick = () => {
        WLOCreditAPIService.getAutorizaciones().then((autorizaciones) => {
            setAutorizaciones(autorizaciones)
        })        
        setShowModal(true);
    };

    const handleTermsAccept = () => {
        setTermsAccepted(true);
        setShowModal(false);
    };

    const handleCheckboxChange = (index: number) => {
        const newCheckedItems = [...checkedItems];
        newCheckedItems[index] = !newCheckedItems[index];
        setCheckedItems(newCheckedItems);
    };

    const handleAcceptAll = () => {
        setCheckedItems(new Array(autorizaciones.length).fill(true));
    };

    const handleTermsDecline = () => {
        setShowModal(false);
    };
    useEffect(()=>{
        if(buscar){
          var valorBusqueda=buscar.search.replace("?","")
          const valorde:any=decodeToken(valorBusqueda)
          sessionStorage.setItem('solicitud',JSON.stringify(valorde))
          if(valorde){
            if(Number(valorde.solicitudnum)>0){
                setNumeroSolicitud(Number(valorde.solicitudnum))
                setvalores(valorde)
              }else{
                toast.error("No tienes La Solicitud")
                          }
          }else{
            toast.error("No tienes La Solicitud")
          }
       
        }
        },[buscar,navigate]);
    useEffect(() => {
        const tI =  JSON.parse(sessionStorage.getItem('tiposIden')!);
        setTiposIdentidad(tI)      
        document.body.classList.add('body-login');
        return () => {
            document.body.classList.remove('body-login');
        };
    }, []);
    const initialValues = {
        Pws_Tip_Identificacion: '',
        Pws_Identificacion: '',
    };

    const validationSchema = Yup.object().shape({
        Pws_Tip_Identificacion: Yup.string()
            .required('( * )'),
        Pws_Identificacion: Yup.string().required('( * )'),
    })

    return (
        <div className="loginfirma17">
            <MenuBar/>
            <div className='loginfirm'>
                <div className='img-logo'/>
                <div className='title-login'>{'Antes que todo validemos tu identidad'}</div>
                <div className='subtitle-login'>{'Ingresa los siguientes datos para continuar'}</div>
                <div className='login-container'>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                    >
                        {() => (
                            <>
                                <Form>
                                    <Row>
                                        <section>
                                            <Field as="select"
                                                disabled={isLoading}
                                                className='form-control-50-credit'
                                                name="Pws_Tip_Identificacion">
                                                <option>{'Tipo de documento'}</option>
                                                {typeIdentification && typeIdentification.WS_TIPOS_IDENTIFICACION_IResult.LstTiposIdent.Datos_TiposIdentificacion.map((item,i) => {
                                                    return (
                                                        <option key={i} value={1}>{item.s_descripcion}</option>
                                                    )
                                                })}
                                            </Field>
                                        </section>
                                        <section>
                                            <div className='form-control-login-text'>{'Documento'}</div>
                                            <Field
                                                className='form-control-50-credit'
                                                id="Pws_Identificacion"
                                                name="Pws_Identificacion"
                                                type="text"
                                                disabled={isLoading}
                                                placeholder='Número de documento'
                                            />
                                            <br/>
                                        </section>
                                        <section>
                                            <h4 className="title-firma">{'Para poder continuar por favor lea y acepte las condiciones de Tratamiento de Datos Personales'}</h4>
                                            <h4 className="title-firma"><strong onClick={handleTermsClick}>{'Leer y Aceptar Condiciones del tratamiento de Datos'}</strong></h4>
                                            <br/>
                                        </section>
                                        <section>
                                            <Fab className="form-control-small-button" variant="extended" type='submit'
                                                size="medium"
                                                color="neutral" aria-label="add">
                                                {'Continuar'}
                                            </Fab>
                                        </section>
                                    </Row>
                                </Form>
                            </>
                        )}
                    </Formik>
                </div>
            </div>
            {showModal && (
                <Modal style={{height:'auto !important'}} isOpen={showModal} toggle={handleTermsDecline}>
                    <ModalHeader toggle={handleTermsDecline}>
                        <h3>Autorización</h3>
                        <div className='textoModal'>
                           {'Acepta la consulta en centrales, manejo de datos y políticas de Finmeridian'}
                        </div>
                    </ModalHeader>
                    <ModalBody style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                        <div className='textoModal'>
                            {autorizaciones.map((autorizacion:any, index:any) => (
                                <div key={index} className="autorizacion-item">
                                    <label>{`${autorizacion.ICodigo}: ${autorizacion.CDescripcion}`}</label>
                                    <ul className='si'>
                                        <li>
                                    <input 
                                        type="checkbox" 
                                        checked={checkedItems[index] || false} 
                                        onChange={() => handleCheckboxChange(index)} 
                                    />
                                        </li>
                                        <li>
                                    <p>Aceptar</p>
                                        </li>
                                    </ul>
                                </div>
                            ))}
                            <div className="aceptar-todo">
                                <button onClick={handleAcceptAll}>Aceptar todo</button>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button 
                            color="primary" 
                            onClick={handleTermsAccept} 
                            disabled={!checkedItems.every(Boolean)}
                        >
                            Aceptar
                        </Button>
                        <Button color="secondary" onClick={handleTermsDecline}>
                            Cancelar
                        </Button>
                    </ModalFooter>
                </Modal>
            )}
        </div>
    )
}