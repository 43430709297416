import React, { useEffect, useState } from "react";
import { Field } from "formik";
import { AdminService } from "../../../services";
import './InfoPay.scss';

interface PayInfoProps {
    errors?: any;
    values?: any;
}

interface ApplicantInfo {
    nombres: string;
    apellidos: string;
    cNumCelular: string;
    telefono: string;
    sTipoIdent: string;
    sNumDoc: string;
    cEmailCod: string;
    metodoPago: 'PSE' | 'Domiciliación' | '';
    entidadFinanciera: string;
    esTitular: boolean;
    tipoCuenta: string;
    numeroCuenta: string;
}

export function PayInfo(props: PayInfoProps) {
    const { errors, values } = props;
    const [entidadesFin, setEntidadesFin] = useState([]);
    const [formData, setFormData] = useState<ApplicantInfo>({
        nombres: values.Pws_Nombres,
        apellidos: values.Pws_Apellidos1,
        cNumCelular: values.Pws_Telefono1,
        telefono: values.Pws_Telefono2,
        sTipoIdent: values.Pws_Tip_Identificacion,
        sNumDoc: values.Pws_Identificacion,
        cEmailCod: values.Pws_Correo,
        metodoPago: '',
        entidadFinanciera: '',
        esTitular: false,
        tipoCuenta: '',
        numeroCuenta: '',
    });

    useEffect(() => {
        cargarEntidades();
    }, []);

    const cargarEntidades = async () => {
        let { getProfesiones2 } = AdminService;
        let entidades = await getProfesiones2();
        setEntidadesFin(entidades.payload.data.LstEntidadBanco.Datos_Entidades);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    return (
        <div className="applicant-info-form">
            <div className="payment-method">
                <div className="form-grid">
                    <div className="form-group">
                        <Field
                            id="nombres"
                            name="nombres"
                            className={`${errors.nombres ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                            placeholder="Ingrese sus nombres"
                            value={formData.nombres}
                            onChange={handleInputChange}
                            disabled
                        />
                    </div>
                    <div className="form-group">
                        <Field
                            id="apellidos"
                            name="apellidos"
                            className={`${errors.apellidos ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                            placeholder="Ingrese los apellidos"
                            value={formData.apellidos}
                            onChange={handleInputChange}
                            disabled
                        />
                    </div>
                    <div className="form-group">
                        <Field
                            id="cNumCelular"
                            name="cNumCelular"
                            className={`${errors.cNumCelular ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                            placeholder="Numero de celular"
                            value={formData.cNumCelular}
                            onChange={handleInputChange}
                            disabled
                        />
                    </div>
                    <div className="form-group">
                        <Field
                            id="telefono"
                            name="telefono"
                            className={`${errors.telefono ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                            placeholder="Numero de telefono"
                            value={formData.telefono}
                            onChange={handleInputChange}
                            disabled
                        />
                    </div>
                    <div className="form-group">
                        <Field
                            id="sTipoIdent"
                            name="sTipoIdent"
                            className={`${errors.sTipoIdent ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                            placeholder="Tipo de identificacion"
                            value={formData.sTipoIdent}
                            onChange={handleInputChange}
                            disabled
                        />
                    </div>
                    <div className="form-group">
                        <Field
                            id="sNumDoc"
                            name="sNumDoc"
                            className={`${errors.sNumDoc ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                            placeholder="Numero de identificacion"
                            value={formData.sNumDoc}
                            onChange={handleInputChange}
                            disabled
                        />
                    </div>
                    <div className="form-group ">
                        <Field
                            id="cEmailCod"
                            name="cEmailCod"
                            className={`${errors.cEmailCod ? 'form-know-you-item-input-smaller display-flex input-error' : 'form-know-you-item-input-smaller display-flex'}`}
                            placeholder="Direccion de correo"
                            value={formData.cEmailCod}
                            onChange={handleInputChange}
                            disabled
                        />
                    </div>
                </div>
                <p>¿Cómo quiere realizar el pago?</p>

                <div className="radio-group">
                    <label>
                        <input
                            type="radio"
                            name="metodoPago"
                            value="PSE"
                            checked={formData.metodoPago === 'PSE'}
                            onChange={handleInputChange}
                        />
                        <span>PSE</span>
                    </label>
                    <label>
                        <input
                            type="radio"
                            name="metodoPago"
                            value="Domiciliación"
                            checked={formData.metodoPago === 'Domiciliación'}
                            onChange={handleInputChange}
                        />
                        <span>Domiciliación</span>
                    </label>
                </div>
            </div>

            {formData.metodoPago === 'Domiciliación' && (
                <div className="domiciliation-details">
                    <div className="titular-question">
                        <p>¿Eres el titular de esta cuenta?</p>
                        <div className="radio-group">
                            <label>
                                <input
                                    type="radio"
                                    name="esTitular"
                                    checked={formData.esTitular === true}
                                    onChange={() => setFormData(prev => ({ ...prev, esTitular: true }))}
                                />
                                <span>Sí</span>
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="esTitular"
                                    checked={formData.esTitular === false}
                                    onChange={() => setFormData(prev => ({ ...prev, esTitular: false }))}
                                />
                                <span>No</span>
                            </label>
                        </div>
                    </div>

                    <div className="account-details">
                        <div className="form-group">
                            <label htmlFor="tipoCuenta">Tipo de cuenta</label>
                            <select
                                id="tipoCuenta"
                                name="tipoCuenta"
                                value={formData.tipoCuenta}
                                onChange={handleInputChange}
                                className={errors.tipoCuenta ? 'form-know-you-item-input-md input-error' : 'form-know-you-item-input-md'}
                            >
                                <option value="">Seleccione...</option>
                                <option value="Ahorros">Ahorros</option>
                                <option value="Corriente">Corriente</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="numeroCuenta">Número de cuenta</label>
                            <input
                                type="text"
                                id="numeroCuenta"
                                name="numeroCuenta"
                                value={formData.numeroCuenta}
                                onChange={handleInputChange}
                                placeholder="Número de cuenta"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="entidadFinanciera">Entidad financiera</label>
                            <select
                                id="entidadFinanciera"
                                name="entidadFinanciera"
                                value={formData.entidadFinanciera}
                                onChange={handleInputChange}
                                className={errors.entidadFinanciera ? 'form-know-you-item-input-md input-error' : 'form-know-you-item-input-md'}
                            >
                                <option value="">Seleccione...</option>
                                {entidadesFin && entidadesFin.map((item: any, key) => (
                                    <option key={key} value={item.s_descripcion}>{item.s_descripcion}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}