import axios from "axios";
import { WloCreditoService } from "../types";
import toast from 'react-hot-toast';


export class WLOCreditAPIService {

    static async addConyugue(conyugue: Partial<WloCreditoService.ConyugueRequest>): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/requests/conyuge', conyugue);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async iniciarProcesoFirma(solicitudPagare: any): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_BACK_WEB + '/ClientePinc/generarPagareDigital', solicitudPagare);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async crearSolCredWeb(solicitudPagare: Partial<WloCreditoService.RequestSolicitudCredito>): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_BACK_WEB + '/SolicitudCredito/crearSolCredito', solicitudPagare);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async buscarPersonaWeb(tipoIden:string , ident: string ): Promise<any> {
        try {
            const { data } = await axios.get(process.env.REACT_APP_BACK_WEB + '/PersonaPinc/buscarUsuarioPorTipoIdent?xiTipoIdent=' +tipoIden + '&xIdent='+ ident);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async radicarSolWeb(xiCodSolCreditot:string , xiCodUser: string , numRol: string): Promise<any> {
        try {
            const { data } = await axios.get(process.env.REACT_APP_BACK_WEB + '/SolicitudCredito/radicarSolicitudCredito?xiCodSolCredito=' +xiCodSolCreditot + '&xiCodUser='+ xiCodUser +'&numRol'+numRol);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async crearInfoCuenta(infoCuenta: any): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_BACK_WEB + '/SolicitudCredito/crearInfoCuenta',infoCuenta);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async getParametros(): Promise<any> {
        try {
            const { data } = await axios.get(process.env.REACT_APP_BACK_WEB + '/Parametros/getByAll');
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async savePerson(person:Partial<WloCreditoService.Persona>): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_BACK_WEB + '/PersonaPinc/crearPersona' , person);
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async savePagare(pagare: Partial<WloCreditoService.SolicitudPagare>): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_BACK_WEB + '/GestionPinc/CrearSolicitudPagare', pagare);
            console.dir(data)
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async findPagare(identificacion: number ): Promise<any> {
        try {
            const { data } = await axios.get(process.env.REACT_APP_BACK_WEB + '/ClientePinc/findSolbyIdent'+'?xIdent='+identificacion );
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async findPersona(identificacion: number ): Promise<any> {
        try {
            const { data } = await axios.get(process.env.REACT_APP_BACK_WEB + '/PersonaPinc/perbyid?xcIdent='+identificacion );
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async getAutorizaciones(): Promise<any> {
        try {
            const { data } = await axios.get(process.env.REACT_APP_BACK_WEB + '/CargaDatosPinc/autorizaciones');
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async getDocumento(datodocumento: Partial<WloCreditoService.documentoSol>): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/documentos-sol/documentofirma', datodocumento);
            console.dir(data)
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async getEmail(InfoSend: {
        id: string,
        number: string,
        deseasMoto: boolean,
        email: string,
        typeIdent: string,
        numbIdent: string,
        codeudorReq: boolean,
        Negado: boolean,
        sargilaft: boolean,
        enestudio: boolean
        cupo: string
    }): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/emails/codeurdoono', InfoSend);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async getEmailCo(InfoSend: {
        id: string,
        number: string,
        deseasMoto: boolean,
        email: string,
        typeIdent: string,
        numbIdent: string,
        codeudorReq: boolean,
        Negado: boolean,
        sargilaft: boolean,
        enestudio: boolean
        cupo: string
    }): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/emails/codeudores1', InfoSend);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async getEmailPropietario(InfoSend: {
        id: string,
        number: string,
        deseasMoto: boolean,
        email: string,
        typeIdent: string,
        numbIdent: string,

    }): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/emails/propietario', InfoSend);
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async cupoEmail(InfoSend: {
        cupo: string,
        number: string,
        deseasMoto: boolean,
        email: string,
        typeIdent: string,
        numbIdent: string,
        codeudorReq: boolean,
        Negado: boolean,
        sargilaft: boolean,
        enestudio: boolean
    }): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/emails/aprobado', InfoSend);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async registraCupo(body: {
        s_r_ident_cliente: String,
        s_r_lin_cred: string,
        s_r_fec_ini_cupo: String,
        s_r_fec_fin_cupo: string,
        s_r_monto_cupo: string
    }): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/requests/registrocupo', body);
            return data;
        } catch (e) {
            return returnResponseError(e);
        }
    }

    static async avanceRot(body: {
        ps_ident_cli: String,
        ps_linea_cred: String,
        pn_monto: String,
        pn_plazo: String,
        pn_for_pla: String,
        pn_for_pag: String,
        pn_periodic: String,
        pf_fecha_sol: any,
        ps_estado: String,
    }): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/requests/avanceRota', body);
            return data;
        } catch (e) {
            return returnResponseError(e);
        }
    }

    static async addCupo(cupo: {
        Pws_Solicitud: string,
        Pws_Identificacion: string,
        Pws_num_Tip_Ident: string,
    }): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/requests/creaCupo', cupo);
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async consultaCupo(cupo: {
        Pws_Solicitud: string,
        Pws_Identificacion: string,
        Pws_num_Tip_Ident: string,
    }): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/requests/consultacupo', cupo);
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async estadocredito(estadocredito: {
        pws_identificacion: string,
        pws_tip_identificacion: string,
        pws_num_solicitud: string,
        pws_estado: string,
    }): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/requests/estadocredito', estadocredito);
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async EvaluaScore(evaluaScore: {
        ps_radicado: string,
        ps_identificacion: string,
        ps_cod_lin: string,
        ps_tipoCliente: string,
        ps_tip_deucode: string,
    }): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/requests/evaluaScore', evaluaScore);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async grabarScore(evaluaScore: {
        ps_radicado: string,
        ps_identificacion: string,
        ps_cod_lin: string,
        ps_tipoCliente: string,
        ps_tip_deucode: string,
    }): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/requests/grabarScore', evaluaScore);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async addAddress(address: {
        Pws_Tip_Consulta: string,
        Pws_Identificacion: string,
        Pws_Tip_Identificacion: string,
        Pws_Tipo: string,
        Pws_Num_Principal: string,
        Pws_Num_Secundario: string,
        Pws_SufijoPri: string,
        Pws_SufijoSec: string,
        Pws_NumComplementario: string,
        Pws_Complemento1: string,
        Pws_NumComplementario1: string,
        Pws_Complemento2: string,
        Pws_NumComplementario2: string,
        Pws_Barrio: string,
    }): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/operations/adrress', address);
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async addCodeudor(codeudor: Partial<WloCreditoService.CodeudorRequest>): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/requests/codeudor', codeudor);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async grabar_cignus(envio: {
        Pws_Identificacion: string,
        Pws_Tip_Identificacion: string,
        Pws_Tip_Perfil: string,
    }): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/graba-sol-cignus', envio);
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async addSolicitudPersona(solicitudPersona: Partial<WloCreditoService.SolicitudPersonaRequest>): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/requests/person', solicitudPersona);
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async addSolicitud(solicitud: Partial<WloCreditoService.SolicitudRequest>): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/requests/', solicitud);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async consultaCupoAsignado(solicitud: Partial<WloCreditoService.ConsultaCuota>): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/EstadoCuenta', solicitud)
            return data;

        } catch (e) {
            throw (e)
        }
    }
    static async consultaSolicitudCygnusDetal(solicitud: Partial<WloCreditoService.SolicitudDetalla>): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/requests/consultadetalle', solicitud);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async consultaSolicitudCygnus(solicitud: Partial<WloCreditoService.SolicitudRequest>): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/requests/consulta', solicitud);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async consultaSolicitudListadoCygnus(solicitud: Partial<WloCreditoService.SolicitudListadoRequest>): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/requests/consultalistado', solicitud);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async consultaSolicitudANI(solicitud: Partial<WloCreditoService.SolicitudRequest>): Promise<any> {
        try {
            const randomForSearch = Math.floor(Math.random() * 100000).toString() + solicitud.Pws_Identificacion!;
            return await WLOCreditAPIService.consultaANI(randomForSearch, solicitud.Pws_Identificacion!, solicitud.Pws_Tip_Identificacion!);
        } catch (e) {
            throw e;
        }
    }

    static async getAccessTokenANI(): Promise<any> {
        try {
            const body = {
                "idAplicacion": "85d2ca22-0f99-4d23-965d-70ad1afdce30",
                "usuario": "usu_EBS",
                "contrasena": "riPNstFiJo",
            }
            const { data } = await axios.post(`https://apippagare.olimpiait.com:8091/Login`, body);
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async consultaANI(idProcesoCliente: string, documento: string, tipoDocumento: string): Promise<any> {
        try {
            const formatedTipoDocumento = WLOCreditAPIService.getTipoDocumentoCygnusToANI(tipoDocumento);
            const accessToken = await WLOCreditAPIService.getAccessTokenANI();
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: accessToken,
                },
            };
            const body = {
                idProcesoCliente: idProcesoCliente,
                documento: documento,
                tipoDocumento: formatedTipoDocumento,
            };
            const { data } = await axios.post(`https://apippagare.olimpiait.com:8091/ANI/ConsultaANI`, body, config);

            // Verificar el estado de la cédula
            if (data.estadoCedula != 0 && data.estadoCedula != 1) {
                // La cédula no es válida, generar una alerta
                toast.error('Esta cedula esta invalidada por la registraduria ',{
                    duration:5000,
                });
                // Puedes agregar aquí cualquier otro manejo de errores si lo necesitas
            } else {
                // La cédula es válida, no se necesita generar una alerta                
                return data;
            }
            // return data;
        } catch (e) {
            throw e;
        }
    }

    static async addPeps(peps: Partial<WloCreditoService.PepsRequest>): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/operations/peps', peps);
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async addReferences(referencias: Partial<WloCreditoService.ReferenciasRequest>): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/operations/references', referencias);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async updateReferences(referencias: Partial<WloCreditoService.ReferenciasRequest2>): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/operations/references', referencias);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async getPlanPagos(EstCuenta: Partial<WloCreditoService.EstCuentaRequest>): Promise<any> {        
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/requests/planPagos', EstCuenta);
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async addFinancialInformation(financialInformation: Partial<WloCreditoService.OperacionesFinancierasRequest>): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/operations/financial', financialInformation);
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async sendEmailModelo(): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/emails');
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async sendEmailCotizacion(emailInfo: {
        dateSolicitud: string,
        product: string,
        price: string,
        cuotas: string,
        cuotaInicial: string,
        valorCuota: string,
        email: string,
    }): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/emails/cotizacion', emailInfo);
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async sendEmailFirmaInicial(emailInfo: {
        email: string,
        dateSolicitud: string,
        numsol: string,
    }): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/emails/firma', emailInfo);
            return data;
        } catch (e) {
            throw e;
        }
    }


    static async sendOTP(body: any): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/reg-otp', body);
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async verifyCodeRegOtp(body: any): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/reg-otp/verify-code', body);
            return returnResponseSuccess(data);
        } catch (e) {
            return returnResponseError(e);
        }
    }

    static async consultaConsolidada(body: {
        datoConsultar: string,
        tipoDocumento: string,
        numeroSolicitud: string,
        fechaSolicitud: string,
        email: string
    }): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/auth/consulta-consolidada', body);
            return returnResponseSuccess(data);
        } catch (e) {
            return returnResponseError(e);
        }
    }




    static async consultDataCredito(body: {
        identificacion: string,
        primer_apellido: string,
        tipoIdentificacion: string,
    }): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/requests/consultadatacredito', body);
            return returnResponseSuccess(data);
        } catch (e) {
            return returnResponseError(e);
        }
    }

    static async consultDataCredito2(body: {
        identificacion: string,
        primer_apellido: string,
        tipoIdentificacion: string,
    }): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/requests/consultadatacredito2', body);
            return data;
        } catch (e) {
            return returnResponseError(e);
        }
    }
    static async regDataCreditoAndSagrilaft(body: {
        Pws_num_solicitud?: string,
        Pws_Identificacion?: string,
        Pws_Tip_Identificacion?: string,
        Pws_Fec_gen?: string,
        Pws_estado_resotp?: string,
        Pws_Tip_val?: string,
        Pws_Puntaje?: string,
        Pws_Entidad_Consul?: string,
        Pws_Num_cuodat?: string,
        Pws_Xml_Dc?: string
    }): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/requests/regDataCredandSagrilaft', body);
            return returnResponseSuccess(data);
        } catch (e) {
            return returnResponseError(e);
        }
    }
    static async consultaDatacredito(body: {
        
        Pws_Identificacion?: string,
        Pws_Tip_Identificacion?: string,
        Pws_Tip_val?: string,
    }): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/requests/consDatacredito', body);
            return returnResponseSuccess(data);
        } catch (e) {
            return returnResponseError(e);
        }
    }

    static async getreferenciacion(referenciacionRequest: Partial<WloCreditoService.referenciacionRequest>) {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/requests/referenciacion', referenciacionRequest);
            return data;
        } catch (e) {
            throw e;
        }
    }
    
    static async addDocuments(body: any): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/documentos-sol', body);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async addDocumentsFTP(file: File, Pws_Identificacion: any, nuevoNombreArchivo: any): Promise<any> {
        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('Pws_Identificacion', Pws_Identificacion);
            formData.append('nuevoNombreArchivo', nuevoNombreArchivo)



            const { data } = await axios.post(process.env.REACT_APP_API + '/Ftp/cargar', formData);

            return data;
        } catch (e) {
            throw e;
        }
    }

    static async ConsultarDocumentos(body: any): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/documentos-sol/consultdocumentos', body);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async addReference(): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/operations/references');
            return data;
        } catch (e) {
            throw e;
        }
    }


    static getTipoDocumentoCygnusToANI(tipoDocumento: string) {
        switch (tipoDocumento) {
            case '13':
                return 'CC'
            case '22':
                return 'CE'
            case '31':
                return 'NIT'
            default:
                return 'CC'
        }
    }
//
    static async getInventario(inventarioRequest: WloCreditoService.InventarioRequest) {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/inventory', inventarioRequest);
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async getSimulador(simuladorRequest: Partial<WloCreditoService.SimuladorCreditoRequest>) {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/simulador-credito/liq', simuladorRequest);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async createCredito(creaCreditoRequest: Partial<WloCreditoService.creCreditRequest>) {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/simulador-credito/crear-credito', creaCreditoRequest);
            return data;
        } catch (e) {
            throw e;
        }
    }

    static async simulaPoliza(simuladorRequest: Partial<WloCreditoService.SimuladorPolizaRequest>) {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/simulador-poliza', simuladorRequest);
            return data;
        } catch (e) {
            throw e;
        }
    }
    static async GenDocumentGarantia(body: {
        pws_ident_cli?: string,
        pws_tip_ident?: string,
        pws_num_sol?: string,
        pws_cod_desti?: string,
        pws_num_radic?: string,
    }): Promise<any> {
        try {
            const { data } = await axios.post(process.env.REACT_APP_API + '/garantia/', body);
            return returnResponseSuccess(data);
        } catch (e) {
            return returnResponseError(e);
        }
    }


}

const returnResponseError = (error: any) => {
    const { response } = error;
    const { data } = response;
    const { errors, message } = data;
    throw { errors, message };
}

const returnResponseSuccess = (response: any) => {
    const { payload, message } = response;
    return { payload, message }
}

